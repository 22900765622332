<template>
  <div :class="appClass">
    <!-- Navbar -->
    <div class="container navbar-container">
      <nav class="navbar navbar-expand-lg navbar-dark custom-navbar">
        <div class="container-fluid">
          <div class="navbar-brand">
            <router-link to="/">
              <img
                alt="Logo"
                class="logo"
                width="192"
                height="31.6"
                src="./assets/minified/logo.webp"
              />
            </router-link>
          </div>

          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarMain"
            aria-controls="navbarMain"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarMain">
            <ul class="navbar-nav mx-auto mb-2 mb-lg-0">
              <li class="nav-item">
                <router-link
                  class="nav-link btn-hover"
                  title="Stake"
                  aria-current="page"
                  exact-active-class="active-link"
                  exact
                  to="/stake"
                  >Stake</router-link
                >
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link btn-hover"
                  title="Validators"
                  aria-current="page"
                  exact-active-class="active-link"
                  exact
                  to="/validators"
                  >Validators</router-link
                >
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link btn-hover"
                  title="Blog"
                  to="/blog"
                  exact-active-class="active-link"
                  >Blog</router-link
                >
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link btn-hover me-0"
                  title="Company"
                  to="/company"
                  exact-active-class="active-link"
                  >Company</router-link
                >
              </li>
            </ul>

            <!-- Right Items -->
            <div class="d-flex right-nav-items">
              <ul class="list-inline d-flex align-items-center mb-0 w-100">
                <li
                  class="list-inline-item d-none d-sm-block"
                  v-if="!isStakePage"
                >
                  <router-link
                    class="list-inline-item fancy-button-border btn-hover"
                    title="Stake"
                    aria-current="page"
                    to="/stake"
                    >Stake now</router-link
                  >
                </li>
                <li
                  class="list-inline-item connected-wallet-button d-none d-sm-block"
                >
                  <div v-if="this.walletConnected" class="dropdown">
                    <a
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      class="fancy-button-border me-0 btn-hover fixed-width wallet-address"
                      title="Wallet"
                      @click="connect_wallet"
                      href="#connect"
                      >{{ buttonLabel }}
                      <span class="caret-style ms-1" v-if="this.walletConnected"
                        >☰</span
                      >
                    </a>
                    <ul class="dropdown-menu dropdown-menu-element mt-3 w-100">
                      <li>
                        <button
                          @click="copy_address"
                          class="dropdown-item text-center"
                          type="button"
                        >
                          Copy address
                        </button>
                      </li>
                      <li>
                        <button
                          @click="disconnect"
                          class="dropdown-item text-center"
                          type="button"
                        >
                          Disconnect
                        </button>
                      </li>
                    </ul>
                  </div>
                  <a
                    v-else
                    class="list-inline-item me-0 fancy-button-border btn-hover fixed-width"
                    @click="connect_wallet"
                    title="Connect Wallet"
                    href="#connect"
                    >{{ buttonLabel }}
                    <span
                      class="caret-style ms-2"
                      v-if="this.walletConnected"
                    ></span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>

      <!-- Add Connect Wallet button for mobile -->
      <div class="d-flex justify-content-end" id="walletMobile">
        <div class="connect-wallet-mobile fancy-button-border">
          <div class="connected-wallet-button">
            <div v-if="this.walletConnected" class="dropdown">
              <a
                data-bs-toggle="dropdown"
                aria-expanded="false"
                class="me-0 btn-hover fixed-width"
                @click="connect_wallet"
                href="#connect"
                >{{ buttonLabel }}
                <span class="caret-style ms-1">☰</span>
              </a>
              <ul class="dropdown-menu dropdown-menu-element mt-3">
                <li>
                  <button
                    @click="copy_address"
                    class="dropdown-item text-center"
                    type="button"
                  >
                    Copy address
                  </button>
                </li>
                <li>
                  <button
                    @click="disconnect"
                    class="dropdown-item text-center"
                    type="button"
                  >
                    Disconnect
                  </button>
                </li>
              </ul>
            </div>
            <a
              v-else
              class="me-0 btn-hover"
              @click="connect_wallet"
              href="#connect"
              >{{ buttonLabel }}
              <span class="caret-style ms-2" v-if="this.walletConnected"></span>
            </a>
          </div>
        </div>
      </div>
    </div>

    <!-- Router View -->
    <router-view></router-view>
  </div>
</template>

<script>
import { Connection } from "@solana/web3.js";
import { API_BASE_URL, NETWORK } from "./constants";
import { mapState } from "vuex";

const getProvider = () => {
  if ("phantom" in window) {
    const provider = window.phantom?.solana;

    if (provider?.isPhantom) {
      console.log("Found the phantom provider");
      return provider;
    } else {
      console.log("not found wallet");
      // window.open("https://phantom.app/", "_blank");
    }
    return null;
  }

  return null;
};

export default {
  name: "App",
  data() {
    return {
      appClass: "",
      copied: false,
      connecting: false,
      currentPath: "",
      walletConnected:
        JSON.parse(localStorage.getItem("walletConnected")) || false,
      pubKey: JSON.parse(localStorage.getItem("pubKey")) || "",
    };
  },
  created() {
    this.setAppClass(this.$route);
  },
  watch: {
    $route(to) {
      this.setAppClass(to);
      this.currentPath = to.path;
    },
  },
  methods: {
    setAppClass(route) {
      this.appClass = route.meta.appClass || "";
    },
    async connect_wallet() {
      this.connecting = true;
      await this.$store.dispatch("connect_wallet");
      this.pubKey = this.$store.state.connectedWalletKey;
      this.connecting = false;
    },
    async getTokenSolValue() {
      try {
        const tokenvalue_response = await fetch(API_BASE_URL + "/v1/price/raisol", {
          method: "GET",
        });
        let tokenvalue_data = await tokenvalue_response.json();
        this.$store.commit("setTokenValue", tokenvalue_data?.raisol || 0);
      } catch (error) {
        console.log("Error while getting token value", error);
      }
      try {
        const response = await fetch(API_BASE_URL + "/v1/price/sol", {
          method: "GET",
        });

        let data = await response.json();
        this.$store.commit("setSolValue", data?.sol_usd || 0);
      } catch (error) {
        console.log("Error while getting token value", error);
      }

      try {
        const response1 = await fetch(API_BASE_URL + "/v1/epochinfo", {
          method: "GET",
        });

        let data1 = await response1.json();
        // console.log("setEpochSeconds", data1);
        this.$store.commit("setEpochSeconds", data1?.epochSeconds || 0);
        // console.log("data1.epoch", data1?.epoch);
        this.$store.commit("setEpochValue", data1?.epoch || 0);
      } catch (error) {
        console.log("Error while getting token/sol value", error);
      }
    },
    disconnect() {
      if (this.$store.state.provider) {
        this.$store.state.provider.disconnect();
      } else {
        this.$store.commit("setConnectedWalletKey", "");
        this.$store.commit("setPhantomSession", "");
        this.$store.commit("setWalletConnected", false);
        localStorage.removeItem("phantomSession");
        // location.reload();
      }
    },

    async copy_address() {
      if (navigator.clipboard) {
        navigator.clipboard
          .writeText(this.$store.state.provider.publicKey.toString())
          .then(() => {
            this.copied = true;
            setTimeout(() => {
              this.copied = false;
            }, 1000);
          });
      } else {
        const tempTextArea = document.createElement("textarea");
        tempTextArea.value = this.$store.state.connectedWalletKey;
        tempTextArea.style.position = "absolute";
        tempTextArea.style.opacity = "0";
        tempTextArea.style.left = "-9999px"; // move it off-screen
        document.body.appendChild(tempTextArea);

        // iOS compatibility
        if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
          tempTextArea.contentEditable = true;
          tempTextArea.readOnly = false;

          const range = document.createRange();
          range.selectNodeContents(tempTextArea);
          const selection = window.getSelection();
          selection.removeAllRanges();
          selection.addRange(range);
          tempTextArea.setSelectionRange(0, 999999);
        } else {
          tempTextArea.select();
        }

        document.execCommand("copy");
        document.body.removeChild(tempTextArea); // Clean up
        this.copied = true;
        setTimeout(() => {
          this.copied = false;
        }, 1000);
      }
    },
  },
  async mounted() {
    this.currentPath = this.$route.path;

    this.connecting = true;
    this.$store.commit(
      "setConnectedWalletKey",
      this.$store.state.provider?.publicKey || ""
    );

    this.getTokenSolValue();

    this.$store.state.provider = getProvider();
    const connection = new Connection(NETWORK);
    this.$store.state.connection = connection;
    if (this.$store.state.provider) {
      let isConnected = this.walletConnected;
      // this.walletConnected = isConnected;
      this.$store.commit("setWalletConnected", isConnected);
      this.$store.commit("setConnectedWalletKey", this.pubKey?.toString());
      // console.log("isConnected", isConnected);
      // Uncomment below code if you want to connect automatically on page load
      // if (!isConnected) {
      //   console.log("connecting...");
      //   await this.$store.state.provider.connect();
      //   this.walletConnected = true;
      // } else {
      //   this.walletConnected = this.$store.state.provider.isConnected;
      //   }
      // this.walletConnected = this.$store.state.provider.isConnected;
      this.$store.state.provider.on("connect", () => {
        this.$store.commit("setWalletConnected", true);
        this.walletConnected = true;
      });

      this.$store.state.provider.on("disconnect", () => {
        this.$store.commit("setWalletConnected", false);
        this.walletConnected = false;
        console.log("Wallet Disconnected");
      });
    }
    // console.log(this.$store.state.provider);

    this.connecting = false;
  },
  computed: {
    buttonLabel() {
      const key = this.pubKey || "";
      console.log("key", key);
      console.log("Connected", this.walletConnected);

      if (this.copied) {
        return "Address copied";
      } else if (this.walletConnected && key) {
        return `${key.substring(0, 6)}...${key.substring(key.length - 6)}`;
      } else if (this.connecting) {
        return "Connecting...";
      } else {
        this.walletConnected = false;
        return "Connect Wallet";
      }
    },
    ...mapState({
      walletConnected: (state) => state.walletConnected,
      provider: (state) => state.provider,
    }),
    isStakePage() {
      return this.currentPath === "/stake";
    },
  },
};
</script>

<style scoped>
.company {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  background-image: url("assets/minified/company_bg.webp");
}
</style>
