import { createStore } from "vuex";
import * as buffer from "buffer";
import bs58 from "bs58";
import nacl from "tweetnacl";

window.Buffer = buffer.Buffer;

function getOS() {
  let operatingSystem = "";
  const userAgent = navigator.userAgent;
  if (/windows phone/i.test(userAgent)) {
    operatingSystem = "Windows Phone";
    // this.operatingSystem = "Windows Phone";
  } else if (/android/i.test(userAgent)) {
    operatingSystem = "Android";
    // this.operatingSystem = "Android";
  } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    operatingSystem = "iOS";
    // this.operatingSystem = "iOS";
  } else if (/Win/.test(userAgent)) {
    operatingSystem = "Windows";
    // this.operatingSystem = "Windows";
  } else if (/Mac/.test(userAgent)) {
    operatingSystem = "MacOS";
    // this.operatingSystem = "MacOS";
  } else if (/Linux/.test(userAgent)) {
    operatingSystem = "Linux";
    // this.operatingSystem = "Linux";
  } else {
    operatingSystem = "Unknown";
    // this.operatingSystem = "Unknown";
  }
  console.log("operatingSystem", operatingSystem);
  return operatingSystem;
}

const buildUrl = (path, params) =>
  `https://phantom.app/ul/v1/${path}?${params.toString()}`;

export default createStore({
  state: {
    provider: null,
    message: "",
    walletConnected:
      JSON.parse(localStorage.getItem("walletConnected")) || false,
    connection: null,
    isSubmitting: false,
    amountToSend: null,
    amountToWithdraw: null,
    graphFilter: "last-15-days",
    tokenValue: 0,
    solValue: 0,
    lastTimeFetched: new Date(),
    connectedWalletKey: JSON.parse(localStorage.getItem("pubKey")) || "",
    dateEpochFilter: "Date",
    epochSeconds: 0,
    epochValue: 0,
    refetch: false,
    isApiDown: false,
    operatingSystem: getOS(),
  },
  mutations: {
    setWalletConnected(state, payload) {
      state.walletConnected = payload;
      localStorage.setItem("walletConnected", JSON.stringify(payload));
    },
    setMessage(state, payload) {
      state.message = payload;
    },
    setGraphFilter(state, payload) {
      state.graphFilter = payload;
    },
    setDateEpochFilter(state, payload) {
      state.dateEpochFilter = payload;
    },
    setTokenValue(state, payload) {
      state.tokenValue = payload;
    },
    setSolValue(state, payload) {
      state.solValue = payload;
    },
    setLastTimeFetched(state, payload) {
      state.lastTimeFetched = payload;
    },
    setConnectedWalletKey(state, payload) {
      state.connectedWalletKey = payload;
      localStorage.setItem("pubKey", JSON.stringify(payload));
    },
    setAmountToSend(state, payload) {
      state.amountToSend = payload;
    },
    setEpochSeconds(state, payload) {
      state.epochSeconds = payload;
    },
    setEpochValue(state, payload) {
      state.epochValue = payload;
    },
    setRefetch(state, payload) {
      state.refetch = payload;
    },
    setIsApiDown(state, payload) {
      state.isApiDown = payload;
    },
  },
  actions: {
    async connect_wallet({ commit, state }) {
      // const onConnectRedirectLink = `${window.location.origin}/stake`;
      // const dappKeyPair = nacl.box.keyPair();
      // const params = new URLSearchParams({
      //   dapp_encryption_public_key: bs58.encode(dappKeyPair.publicKey),
      //   cluster: "testnet",
      //   app_url: "https://phantom.app",
      //   redirect_link: onConnectRedirectLink,
      // });
      // let url = buildUrl("connect", params);
      // window.open(url);
      // commit("setMessage", "");
      // let _url = `https://phantom.app/ul/browse/${encodeURIComponent(
      //   "https://rakurai.io/stake"
      // )}?ref=${encodeURIComponent("https://rakurai.io/stake")}`;

      if (!state.provider) {
        if (
          state.operatingSystem === "Android" ||
          state.operatingSystem === "iOS"
        ) {
          let _url = `https://phantom.app/ul/browse/${encodeURIComponent(
            "https://rakurai.io/stake"
          )}?ref=${encodeURIComponent("https://rakurai.io/stake")}`;
          window.open(_url, "_self");
          return;
        } else {
          window.open("https://phantom.app/", "_blank");
          return;
        }
      }
      try {
        await state.provider.connect();
        commit("setWalletConnected", true);
        commit("setConnectedWalletKey", state.provider.publicKey.toString());
        console.log("Connected to wallet");
      } catch (err) {
        commit("setWalletConnected", false);
        console.log(
          "User cancelled connect request or wallet needs to be created",
          err
        );
        if (err.code === 4001) {
          commit("setMessage", "User rejected the request");
        } else {
          commit("setMessage", "Error occurred while connecting wallet.");
        }
      }
    },
  },
  modules: {},
});
