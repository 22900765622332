export const API_BASE_URL = "https://api.rakurai.io/api";
export const INTERNAL_API =
  process.env.VUE_APP_API_BASE_URL || "https://rakurai.io/xapi";
export const NETWORK = "https://api.testnet.solana.com";

export const INFLUX = {
  DB: "metrics",
  TABLE: "validator",
  BASE_URL: "https://api.rakurai.io:8086",
  USERNAME: "metrics_read",
  PASSWORD: "read",
};

// True TPS data and average data
export const TRUE_TPS = {
  DB: "tmp",
  TABLE: "graph",
  BASE_URL: "http://18.117.128.190:8086",
};

export const NETWORK_FEE = "https://api.rakurai.io/api/networkfee";
export const NODE_LINK = "https://api.rakurai.io/api/nodelink";
