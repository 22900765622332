<template>
  <div ref="socialIcons" class="fixed-social-icons">
    <ul class="social-icons-list">
      <li>
        <a href="https://x.com/Rakurai_io" target="_blank" title="Twitter">
          <img :src="twitterIcon" width="20" height="20" alt="Twitter" />
        </a>
      </li>
      <li>
        <a
          href="https://www.linkedin.com/company/rakurai/"
          target="_blank"
          title="LinkedIn"
        >
          <img :src="linkedinIcon" width="20" height="20" alt="LinkedIn" />
        </a>
      </li>
      <li>
        <a
          href="https://t.me/rakurai_official"
          target="_blank"
          title="Telegram"
        >
          <img :src="telegramIcon" width="20" height="20" alt="Telegram" />
        </a>
      </li>
      <li>
        <a href="https://discord.gg/XS7GmnmCJg" target="_blank" title="Discord">
          <img :src="discordIcon" width="20" height="20" alt="Discord" />
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import twitterIcon from "@/assets/twitter.png";
import linkedinIcon from "@/assets/linkedin-social.png";
import telegramIcon from "@/assets/telegram.png";
import discordIcon from "@/assets/discord.png";

export default {
  name: "SocialIcons",
  data() {
    return {
      twitterIcon,
      linkedinIcon,
      telegramIcon,
      discordIcon,
    };
  },
  mounted() {
    let elements = document.getElementsByClassName("layoutfix");
    if (elements.length) {
      let staticBackground = elements[0];
      var scrollWidth = window.innerWidth - staticBackground.clientWidth;
      const social = this.$refs.socialIcons;
      if (social) {
        const computedStyle = window.getComputedStyle(social);
        const rightValue = computedStyle.right;
        const numericRight = parseFloat(rightValue);

        if (!isNaN(numericRight)) {
          const newRightValue = numericRight - scrollWidth;

          social.style.right = `${newRightValue}px`;
          // console.log(`Updated 'right' value: ${newRightValue}px`);
        }
      }
      // console.log(scrollWidth);
    }
  },
};
</script>

<style scoped>
.fixed-social-icons {
  position: absolute;
  top: 50%;
  right: 21px;
  transform: translateY(-50%);
  color: #fff;
  z-index: 999;
}

.social-icons-list {
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
}
.social-icons-list a {
  width: 20px;
  height: 20px;
  display: block;
  line-height: 14px;
}

.social-icons-list li:not(:last-child) {
  margin-bottom: 24px;
}
</style>
