<template>
  <div class="home-bg">
    <div class="container">
      <!-- Main Heading -->
      <div class="row">
        <div class="col">
          <div class="hero-section">
            <h1 class="offset-md-1 col-md-10 hero-title mx-auto">
              HIGH YIELD SOLANA STAKING POWERED BY SUPERCHARGED NODES
            </h1>
            <div class="hero-button-section d-flex justify-content-center">
              <div class="contain-hero-section d-flex justify-content-center">
                <div>
                  <router-link
                    aria-current="page"
                    to="/stake"
                    class="hero-button fancy-button-border"
                    title="STAKE NOW"
                    >STAKE NOW</router-link
                  >
                </div>

                <div>
                  <router-link
                    aria-current="page"
                    to="/validators"
                    class="hero-button fancy-button-border"
                    title="RUN VALIDATOR"
                    >RUN VALIDATOR</router-link
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Home Bottom -->
      <div class="container home-bottom-section">
        <div
          class="contain-bottom-section d-flex align-items-center justify-content-center"
        >
          <div class="bottom-section first text-center">
            <h2>5X FASTER NODES</h2>
            <p class="mb-0">Best Performance</p>
          </div>
          <div class="bottom-section text-center">
            <h2>30% HIGHER APY</h2>
            <p class="mb-0">Higher Rewards</p>
          </div>
        </div>
      </div>

      <!-- Border line -->
      <hr class="desktop-line" />
    </div>

    <!-- Home social icons -->
    <SocialIcons />

    <!-- Rectangle shadows -->
    <div class="rectangle-bottom"></div>
    <div class="rectangle-top"></div>
    <div class="thunderbolt"></div>
    <!-- <div class="right-fill"></div> -->
  </div>
</template>

<script>
import SocialIcons from "@/components/SocialIcons";
export default {
  name: "Home",
  components: {
    SocialIcons,
  },
  mounted() {
    let elements = document.getElementsByClassName("home");
    let staticBackground = elements[0];
    staticBackground.style = null;
  },
};
</script>

<style>
.home {
  background-image: url("../assets/minified/home_bg.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: rgba(4, 16, 26, 1);

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}

.thunderbolt {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background-image: url("../assets/minified/thunder_bolt.webp");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  z-index: 0;
  width: 60vw;
  overflow: none;
}

@media screen and (max-width: 1280px) and (min-width: 1025px) {
  .hero-title {
    width: 1050px;
  }
}
@media screen and (max-width: 768px) {
  .thunderbolt {
    width: 99.8vw;
  }
}
</style>
